import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column } from 'components/atoms';

const HtmlSection = ({ data, className }) => {
  return (
    <Section className={cn('html-section', `${className}`)}>
      <Container>
        <Row>
          <Column className={`offset-lg-2`} lg="8">
            <div
              className={cn('html-section__content')}
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

HtmlSection.defaultProps = {
  data: {},
  className: '',
};

HtmlSection.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default HtmlSection;
